import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "./conf/i18n";
import Loader from "./components/utils/loader/loader";
// import {Analytics} from '@vercel/analytics/react';
// import {SpeedInsights} from '@vercel/speed-insights/react';

// Lazy loaded components
const Navbar = lazy(() => import("./components/navbar/navbar"));
const AboutUs = lazy(() => import("./components/pages/about/aboutUs"));
const OurServices = lazy(() => import("./components/pages/services/OurServices"));
const Projects = lazy(() => import("./components/pages/projects/Projects"));
const ServiceDetailSes = lazy(() => import("./components/pages/services/detailed/serviceDetailSes"));
const ServiceDetailOtg = lazy(() => import("./components/pages/services/detailed/serviceDetailOtg"));
const ServiceDetailCsg = lazy(() => import("./components/pages/services/detailed/serviceDetailCsg"));
const FlowPro = lazy(() => import("./components/pages/projects/flow_pro/flowPro"));
const ContactUs = lazy(() => import("./components/pages/contact/contactUs"));
const Home = lazy(() => import("./components/pages/home/home"));
const Footer = lazy(() => import("./components/footer/footer"));
const ScrollToTopButton = lazy(() => import("./components/utils/scrollToTopButton"));
const ScrollToSection = lazy(() => import("./components/utils/scrollToSection"));
const TechStack = lazy(() => import("./components/pages/tech_stack/techStack"));
const NotFound = lazy(() => import("./components/notFound"));

const App: React.FC = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <Suspense fallback={<Loader />}>
                    <Navbar />
                    <ScrollToSection />
                    <Routes>
                        <Route path="/" element={
                            <div>
                                <Home />
                                <Projects />
                                <OurServices />
                                <AboutUs />
                                <TechStack />
                                <ContactUs />
                            </div>
                        } />
                        <Route path="/ses" element={<ServiceDetailSes />} />
                        <Route path="/otg" element={<ServiceDetailOtg />} />
                        <Route path="/csg" element={<ServiceDetailCsg />} />
                        <Route path="/flowpro" element={<FlowPro />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Footer />
                    <ScrollToTopButton />
                    {/*<Analytics mode={'production'} debug={false}/>*/}
                    {/*<SpeedInsights />*/}
                </Suspense>
            </Router>
        </I18nextProvider>
    );
};

export default App;
